import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCategoryById,
  getFromLocation,
  getPriceDetails,
  getToLocation,
} from "../../statemanagement/common/action";

import _ from "lodash";
import {
  ADULT_CHILD_POPUP,
  BOOKING_DATA,
  LOCATION_POPUP,
  PASSENGER_DETAILS_POPUP,
  PRODUCT_BYID,
  SELECT_TIMER_POPUP,
  USER_DETAILS_POPUP,
  HANDLE_PRODUCT_PAGE_MODAL
} from "../../statemanagement/common/types";
import UserDetailsPopup from "../../component/UserDetailsPopup";
import BookingModule from "../../component/BookingModule/BookingModule";
import AdultChildModal from "../../component/Modal/AdultChildModal/AdultChildModal";
import DateAndTimeAvailability from "../../component/Modal/DateAndTimeAvailability";
import Modal from "../../component/Modal/Modal";
import LocationDropdown from "./LocationDropdown";
import Loader from "../../component/Loader";
import LocationModal from "../../component/Modal/LocationModal/LocationModal";
import Stepper from "../../component/Stepper/Stepper";

const Product = () => {
  const {
    categoryList,
    categoryById,
    userDetailsPopup,
    commonLoader,
    bookingData,
    toLocationList,
    locationPopup,
    selectTimerPopup,
    fromLocationList,
    productById,
    adultChildPopup,
    isProductPageModalOpen
  } = useSelector((state) => state.common);


const [timeerPopup,setTimerPopup] = useState(false)

useEffect(()=>{

    setTimerPopup(selectTimerPopup)

},[selectTimerPopup])

  const [productDetails, setProductDetails] = useState({
    product: "",
    fromLocation: "",
    toLocation: "",
  });

  const { id } = useParams();
  let dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const totalPages = Math.ceil(categoryList.length / itemsPerPage);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  useEffect(() => {
    dispatch(getCategoryById(id));
  }, []);

 

  function closeUserDetailsPopup(e) {
    dispatch({ type: USER_DETAILS_POPUP, data: false });
  }
  const isNullOrEmpty = (value) => {
    if (value === null || value === undefined) return true;

    if (typeof value === "string" && value.trim() === "") return true;

    if (Array.isArray(value) && value.length === 0) return true;

    if (
      typeof value === "object" &&
      !Array.isArray(value) &&
      Object.keys(value).length === 0
    )
      return true;

    return false;
  };

  function handleContinue(e, selectedTrip, isreturn) {
    if (!isNullOrEmpty(e)) {
      if (selectedTrip === "one-way") {
        let detail = {
          trip: selectedTrip,
          ...e,
          is_return: isreturn,
        };
        dispatch({ type: ADULT_CHILD_POPUP, data: true });
        dispatch({ type: BOOKING_DATA, data: detail });
      } else {
        if (selectedTrip === "round-trip") {
          if (!isNullOrEmpty(e?.return)) {
            let detail = {
              trip: selectedTrip,
              ...e,
              is_return: isreturn,
            };
            dispatch({ type: ADULT_CHILD_POPUP, data: true });
            dispatch({ type: BOOKING_DATA, data: detail });
          } else {
            alert("Please Select Return Date and Time");
          }
        }
      }
    } else {
      alert("Please Select Depart Date and Time");
    }
  }
  const closeModal = () => {
    dispatch({ type: HANDLE_PRODUCT_PAGE_MODAL, data: false });
  };
  function handleAdultChildSubmit(ticket, passenger) {
    dispatch({
      type: BOOKING_DATA,
      data: { ...bookingData, ticket, price: passenger },
    });
    // navigate("/allDetailsPage");
    dispatch({ type: USER_DETAILS_POPUP, data: true });
  }
  const closeAdultModal = () => {
    dispatch({ type: ADULT_CHILD_POPUP, data: false });
  };
  const proccedToBook = (i) => {

    // dispatch(getFromLocation(i?.id));
    // setProductDetails((prev) => ({
    //   ...prev,
    //   product: i.id,
    // }));
    dispatch({ type: HANDLE_PRODUCT_PAGE_MODAL, data: true });
  };


  const handleLocation = (e) => {
    const { name, value } = e.target;
    if (name === "fromLocation") {
      setProductDetails((prev) => ({
        ...prev,
        [name]: value,
        toLocation: "",
      }));
      dispatch(getToLocation(productDetails?.product, value));
    } else {
      setProductDetails((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  

  function closeLocationModel(){
    dispatch({type:LOCATION_POPUP,data:false})
  }

  const handleSearch = () => {
    dispatch(
      getPriceDetails(productDetails?.fromLocation, productDetails?.toLocation)
    );
  };

  return (
    <>
     {commonLoader && <Loader type={"bus"} />}
     <Modal
        isOpen={isProductPageModalOpen}
        width={"width"}
        title={"Booking Process"}
        onClose={closeModal}
        content={
          <Stepper
            steps={["Step 1", "Step 2", "Step 3","Step 4"]}
            stepContent={[
              <LocationModal />,      // here locationModal is reuse component
              <DateAndTimeAvailability />,
              <AdultChildModal />,
              <UserDetailsPopup />
           
            ]}
          />
        }
      />

      <section className="max-w-7xl mx-auto my-10">
        <div className="p-4">
          <h1 className="mb-2 font-semibold text-[19px]">
            Product Founds ({categoryById?.products?.length})
          </h1>
          {!_.isEmpty(categoryById?.products)
            ? categoryById?.products?.map((i, index) => (
                <div
                  key={index}
                  className="w-full border border-[#FFB703] rounded-lg p-4 mb-4 shadow-lg overflow-hidden"
                >
                  <div className="w-full grid grid-cols-12 gap-3">
                    <div className="col-span-3">
                      <img
                        src={i?.img}
                        alt="Shuttle"
                        className=" object-cover rounded-lg"
                      />
                    </div>
                    <div className="col-span-4 w-full flex justify-between">
                      <div className="w-full">
                        <h2 className="w-full text-lg text-[#212121] font-bold">
                          {i?.name}
                        </h2>
                        <p className="">
                          Duration : {i?.estimated_duration} min
                        </p>
                        <p className="">Code: {i?.code}</p>
                        <p className=" w-full font-normal line-clamp-2">
                          {i?.long_desc}
                        </p>
                      </div>
                    </div>
                    <div className="col-span-5 w-full flex flex-col justify-between gap-5  ">
                      <div className="text-start flex flex-col items-start ">
                        <h1 className="text-sm">Price</h1>
                        <div className="space-x-4">
                          {i?.product_price?.map((j) => (
                     
                            <span className=" w-full">
                              {j?.name} Single Trip : - {j?.single_ride} Round
                              Trip : - {j?.round_ride}
                              <br />
                            </span>
                          ))}
                          <span className="text-[#212121] text-[15px] font-semibold line-through">
                            {i?.originalPrice}
                          </span>
                        </div>
                      </div>
                      <div className="inline-flex justify-center">
                        <button
                          onClick={() => proccedToBook(i)}
                          type="button"
                          className="w-full inline-block bg-gradiant-yellow-secondary font-semibold px-10 py-2.5 text-[#212121] rounded-lg"
                        >
                          Proceed to Book
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : "Product Not Found"}
        </div>
        <Modal
          isOpen={timeerPopup}
          width={"width"}
          title="Select trip"
          content={<DateAndTimeAvailability handleContinue={handleContinue} />}
          onClose={closeModal}
        />

        <Modal
          isOpen={adultChildPopup}
          title="Select Adult & Child"
          content={
            <AdultChildModal
              adultChildPrice={"$300"}
              handleAdultChildSubmit={handleAdultChildSubmit}
            />
          }
          onClose={closeAdultModal}
        />
        <Modal
          isOpen={userDetailsPopup}
          content={
            <UserDetailsPopup details={productById?.roundtrip?.product_price} />
          }
          onClose={closeUserDetailsPopup}
        />

        {totalPages > 1 && (
          <Pagination
            handlePageClick={handlePageClick}
            currentPage={currentPage}
            totalPages={totalPages}
          />
        )}
      </section>
    </>
  );
};

export default Product;

const Pagination = ({ handlePageClick, currentPage, totalPages }) => {
  return (
    <>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageClick(1)}
          className={`px-3 py-1 mx-1 ${
            currentPage === 1
              ? "bg-yellow-500 text-white"
              : "bg-gray-200 text-gray-700"
          } rounded-lg`}
        >
          1
        </button>
        {currentPage > 3 && <span className="px-3 py-1 mx-1">...</span>}
        {currentPage > 2 && (
          <button
            onClick={() => handlePageClick(currentPage - 1)}
            className="px-3 py-1 mx-1 bg-gray-200 text-gray-700 rounded-lg"
          >
            {currentPage - 1}
          </button>
        )}
        {currentPage !== 1 && currentPage !== totalPages && (
          <button
            onClick={() => handlePageClick(currentPage)}
            className="px-3 py-1 mx-1 bg-yellow-500 text-white rounded-lg"
          >
            {currentPage}
          </button>
        )}
        {currentPage < totalPages - 1 && (
          <button
            onClick={() => handlePageClick(currentPage + 1)}
            className="px-3 py-1 mx-1 bg-gray-200 text-gray-700 rounded-lg"
          >
            {currentPage + 1}
          </button>
        )}
        {currentPage < totalPages - 2 && (
          <span className="px-3 py-1 mx-1">...</span>
        )}
        <button
          onClick={() => handlePageClick(totalPages)}
          className={`px-3 py-1 mx-1 ${
            currentPage === totalPages
              ? "bg-yellow-500 text-white"
              : "bg-gray-200 text-gray-700"
          } rounded-lg`}
        >
          {totalPages}
        </button>
      </div>
    </>
  );
};
