import React, { useState } from "react";
import "./alldetailspage.css";
import Modal from "../../component/Modal/Modal";
import { DynamicForm } from "../../component/DynamicForm/DynamicForm";

export default function AllDetailsPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleOpenDyanmicForm() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
  }

  const formData = [
    {
      _id: "6130b24ecc34eb16be2cb95e",
      status: "active",
      sectionname: "default-section-1",
      sectiondisplayname: "Personal Information",
      formname: "member",
      formid: "599673a925f548d7dbbd7c86",
      displayname: "Joining Date",
      fieldname: "joining_date",
      fieldtype: "datepicker",
      required: false,
      colspan: "2",
      formorder: 1,
    },
    {
      _id: "5f9f96dd0d40217078c91b9c",
      status: "active",
      formid: "599673a925f548d7dbbd7c86",
      formname: "member",
      fieldtype: "text",
      fieldname: "fullname",
      sectionname: "default-section-1",
      sectiondisplayname: "Personal Information",
      displayname: "Full Name",
      required: true,
      colspan: "2",
      validations: [],
      lookupdata: [],
      formorder: 2,
    },
    {
      _id: "610a87fd500c505b1ffd665a",
      lookupdata: [
        {
          key: "Male",
          value: "Male",
        },
        {
          key: "Female",
          value: "Female",
        },
      ],
      status: "active",
      sectionname: "default-section-1",
      sectiondisplayname: "Personal Information",
      formname: "member",
      formid: "599673a925f548d7dbbd7c86",
      displayname: "Gender",
      fieldname: "gender",
      fieldtype: "radio",
      required: false,
      colspan: "2",
      formorder: 3,
    },
  ];

  return (
    <section className="max-w-7xl mx-auto  my-3 mb-5 all_details_page_container">
      <div className="checkout-container">
        {/* Header */}
        <header className="checkout-header">
          <h1>Booking Details</h1>
          <button className="cancel-booking" onClick={handleOpenDyanmicForm}>
            Cancel My Booking
          </button>
        </header>

        {/* Progress Steps */}

        {/* Main Content */}
        <main className="checkout-content">
          {/* Left Section */}
          <div className="left-section">
            {/* Booking Status */}
            <div className="booking-status">
              <p>
                <span className="status-icon">⚠️</span> Your Booking is on Hold.
                We hold your booking until <b>Feb 14, 12:00 AM</b>. If your
                reserve changes, we will get back to you.
              </p>
            </div>

            {/* Booking Info */}
            <div className="booking-info">
              <h3>Book Information</h3>
              <p className="success-message">
                ✅ Congratulations! We have sent your book details to the
                vehicle owner.
              </p>
              <div className="info-row">
                <p>
                  <b>Full Name:</b> Ahmed Bin Ali
                </p>
                <p>
                  <b>Email:</b> ahmedbinali@gmail.com
                </p>
                <p>
                  <b>Phone Number:</b> +221402040785
                </p>
              </div>
            </div>

            {/* Payment Detail */}
            <div className="payment-detail">
              <h3>Payment Detail</h3>
              <form>
                <label>
                  Card Number
                  <input type="text" placeholder="1234 - 2133 - 9832 - 3200" />
                </label>
                <div className="form-row">
                  <label>
                    Expire Date
                    <select>
                      <option>12</option>
                      <option>11</option>
                      <option>10</option>
                    </select>
                    <select>
                      <option>2030</option>
                      <option>2029</option>
                    </select>
                  </label>
                  <label>
                    CVC/CVV
                    <input type="text" placeholder="453" />
                  </label>
                </div>
                <p className="cancellation-policy">
                  <b>Cancellation Policy:</b> At Garazi, we understand that
                  plans can change unexpectedly.{" "}
                  <a href="#">See more details</a>.
                </p>
              </form>
            </div>
          </div>

          {/* Right Section */}
          <div className="right-section">
            <h3>Summary</h3>
            <p>
              <b>Total Vehicles:</b> 1 Vehicle
            </p>
            <p>
              <b>Pickup Location:</b> Jl. Raya Ponorogo - Trenggalek, Bancangan
            </p>
            <p>
              <b>Pickup Date:</b> Mon, 4 Feb 2024 - 10:00
            </p>
            <p>
              <b>Return Date:</b> Thu, 8 Feb 2024 - 10:00
            </p>
            <h4>Price Details</h4>
            <p>Trip Price: USD 230/day</p>
            <p>Delivery Fee: USD 50</p>
            <p>Duration: 4 days</p>
            <p>
              <b>Total:</b> USD 1,850.00
            </p>
            <label>
              <input type="checkbox" /> By clicking this, I agree to Garazi{" "}
              <a href="#">Terms & Conditions</a> and{" "}
              <a href="#">Privacy Policy</a>.
            </label>
            <button className="pay-button">Pay for My Booking</button>
          </div>
        </main>
      </div>

      <Modal
        isOpen={isModalOpen}
        title="Dynamic form"
        content={<DynamicForm formData={formData} />}
        onClose={closeModal}
      />
    </section>
  );
}
