import React from "react";

const LocationDropdown = ({ label, value, name, onChange, options, placeholder, icon }) => {
  return (
    <div className="lable_input_group_container">
      <label>{label}</label>
      <div className="input_group_booking_module">
        <span className="icon_booking_module">{icon}</span>
        <select
          className="dropdown_booking_module"
          value={value}
          name={name}
          onChange={onChange}
        >
          <option>{placeholder}</option>
          {options?.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default LocationDropdown;
