


import React from "react";
import "./loader.css"; // Import CSS for animations and styles

const Loader = ({ type }) => {
  const themes = {
    bus: {
      color: "rgba(100, 100, 100, 0.5)",
      gif: "/images/stockimage/bus_animation.gif", // Bus GIF URL
    },
    // car: {
    //   color: "#e74c3c",
    //   gif: "https://media.giphy.com/media/26AHONQ79FdWZhAI0/giphy.gif", // Car GIF URL
    // },
    // bike: {
    //   color: "#9b59b6",
    //   gif: "https://media.giphy.com/media/l4pTfx2qLszoacZRS/giphy.gif", // Bike GIF URL
    // },
  };

  const { color, gif } = themes[type] || {
    color: "#3498db",
    gif: "https://media.giphy.com/media/xTk9ZvMnbIiIew7IpW/giphy.gif", // Default Loading GIF
  };

  return (
    <div
      className="loader-container"
      style={{ backgroundColor: color }}
    >
      <div className="loader-animation">
        {/* Display the GIF */}
        <img
          src={gif}
          loading="lazy"
          alt={`${type || "loading"} gif`}
          className={`loader-gif ${type === "bus" ? "move-bus" : ""}`}
        />
        <p className="loader-text">Loading...</p>
      </div>
    </div>
  );
};

export default Loader;

// import React from "react";
// import "./loader.css"; // Import CSS for animations and styles

// const Loader = ({ type }) => {
//   const themes = {
//     bus: { color: "rgba(100, 100, 100, 0.5)", icon: "🚌" },
//     car: { color: "#e74c3c", icon: "🚗" },
//     bike: { color: "#9b59b6", icon: "🏍️" },
//   };

//   const { color, icon } = themes[type] || { color: "#3498db", icon: "⏳" };

//   return (
//     <div
//       className="loader-container"
//       style={{ backgroundColor: color }}
//     >
//       <div className="loader-animation">
//         <span className={`loader-icon ${type === "bus" ? "move-bus" : ""}`}>
//           {icon}
//         </span>
//         <p className="loader-text">Loading...</p>
//       </div>
//     </div>
//   );
// };

// export default Loader;