import React, { useState, useEffect } from "react";
import "./locationmodal.css";
import { useDispatch, useSelector } from "react-redux";
import LocationDropdown from "../../../pages/product/LocationDropdown";
// import { getToLocation } from "../../../statemanagement/common/action";
import {
  getPriceDetails,
  getToLocation,
} from "../../../statemanagement/common/action";
import { STORE_STEPPER_FORMDATA } from "../../../statemanagement/common/types";
import { forEach } from "lodash";

export default function LocationModal() {
  let dispatch = useDispatch();

  const { productById,toLocationList, fromLocationList, userSelectedRoute } =

    useSelector((state) => state?.common);
  const [selectedTrip, setSelectedTrip] = useState({
    id:"one-way",
    name:"tripType",
    value:"oneway"
  });

  const [productDetails, setProductDetails] = useState({
    product: {id:"", name:""},
    fromLocation: { id: "", name: "" },
    toLocation: { id: "", name: "" },
  });

  // fetch the userSelectedRoute info from the store and store in state
  useEffect(() => {
    if (
      userSelectedRoute?.fromLocation?.id != "" &&
      userSelectedRoute?.toLocation?.id != "" &&
      userSelectedRoute?.product?.id != ""
    ) {
      setProductDetails(userSelectedRoute);

      // let step1FormField = ste
      for (let key in userSelectedRoute){
        if(userSelectedRoute.hasOwnProperty(key)){
          let subObject = userSelectedRoute[key];
          handleStepperFormFieldPayload(key, subObject?.id, subObject?.name, "step1");
        }
      }
    }
  }, [userSelectedRoute]);

  useEffect (() => {
    if(selectedTrip?.id != null || selectedTrip?.id != "" || selectedTrip?.id != undefined){
      handleStepperFormFieldPayload(selectedTrip?.name, selectedTrip?.id,selectedTrip?.value, "step1")
    }
  },[selectedTrip])


// generating payload for api
  const handleStepperFormFieldPayload = (key, id, value, currentStep) => {
    let formFieldPayload = {
      key:key, value:{id:id, value:value}, step:currentStep
    }
    dispatch({type:STORE_STEPPER_FORMDATA, data:formFieldPayload})
  }


  //store selected trip in state
  const handleTripSelection = (e) => {
    setSelectedTrip({
      ...selectedTrip,
      id: e.target.id,
      value: e.target.value,
    });
  };

  // storing from location and to location
  //and searching for the "to location" as per the selected from location
  const handleLocation = (e) => {
    const { name, value, selectedIndex } = e.target;
    if (name === "fromLocation") {
      let selectedFromLocation = fromLocationList.at(selectedIndex - 1);
      setProductDetails((prev) => ({
        ...prev,
        [name]: {
          id: selectedFromLocation.id,
          name: selectedFromLocation.name,
      },
      }));
      handleStepperFormFieldPayload(name, selectedFromLocation.id, selectedFromLocation.name, "step1");
      dispatch(getToLocation(productDetails?.product?.id, value));
    } else {
      let selectedToLocation = toLocationList.at(selectedIndex - 1);
      // fetch price details about the route as per the session
      setProductDetails((prev) => ({
        ...prev,
        [name]: { id: selectedToLocation.id, name: selectedToLocation.name },
      }));

      handleStepperFormFieldPayload(name, selectedToLocation.id, selectedToLocation.name, "step1");

      dispatch(
        getPriceDetails(
          productDetails?.fromLocation?.id,
          selectedToLocation.id,
          productDetails?.product?.id
        )
      );
      // store product details in to location
      
    }


  };

  return (
    <>
      <div className="location_modal_content_container">
        <LocationDropdown
          label="From"
          value={productDetails?.fromLocation?.name}
          name="fromLocation"
          onChange={handleLocation}
          options={fromLocationList}
          placeholder={
            productDetails?.fromLocation?.id
              ? fromLocationList.find(
                  (i) => i.id === productDetails?.fromLocation.id
                )?.name
              : " Choose From Location"
          }
          icon="📍"
        />
        <LocationDropdown
          label="To"
          value={productDetails?.toLocation?.name}
          name="toLocation"
          onChange={handleLocation}
          options={toLocationList}
          placeholder={
            productDetails?.toLocation?.id
              ? toLocationList.find(
                  (i) => i.id === productDetails?.toLocation?.id
                )?.name
              : " Choose From Location"
          }
          icon="📍"
        />
      </div>
      <p className="select_trip_text1">
        Select trip{" "}
        <label>
          (Round trips are subject to availability and may not be available for
          all the locations.)
        </label>
      </p>

      <div className="flex one_way_container mt-3  gap-4">
        <div className="radio_label_container">
          <input
            type="radio"
            id="one-way"
            name="tripType"
            value="oneway"
            checked={selectedTrip?.value === "oneway"} // Bind to state
            onChange={(e) => handleTripSelection(e)} // Update state
          />
          <label htmlFor="one-way">One Way</label>
        </div>
        {productById?.is_roundtrip ? (
          <div className="radio_label_container">
            <input
              type="radio"
              id="round-trip"
              name="tripType"
              value="roundtrip"
              disabled={!productById?.is_roundtrip}
              checked={selectedTrip?.value === "roundtrip"} // Bind to state
              onChange={(e) => handleTripSelection(e)} // Update state
            />
            <label htmlFor="round-trip">Round Trip</label>
          </div>
        ) : null}
      </div>
    </>
  );
}
