import React, { useState } from "react";
import './stepper.css'
import { useDispatch, useSelector } from "react-redux";

import { TRACK_STEPPER_STEPS, STEPPER_FORMDATA_ERROR_HANDLING } from "../../statemanagement/common/types";
import { requiredFieldStepperValidation, returnDateNotSmallerThanDepartureDate,returnTimeNotSmallerThanDepartureDateAndTime, totalTravelersNotGreaterThanSessionAvailabilityValidation, childWithNoAdultValidation, noSpecialCharacterAndSpacesValidation, emailValidation, phoneNoLengthValidation  } from "../../helper/validationLogic";


export default function Stepper({ steps, stepContent }) {
  const {
    bookingProcessStepsTracker,
    stepperFormData,
    stepperFormDataErrorHandling
  } = useSelector((state) => state.common);
  const dispatch = useDispatch();


  const handleNext = () => {
    let ifValidatedAndErrorFound = performValidation();
    if(!ifValidatedAndErrorFound) {
      if (bookingProcessStepsTracker?.currentStep < bookingProcessStepsTracker?.totalStep) dispatch({type:TRACK_STEPPER_STEPS, data: bookingProcessStepsTracker?.currentStep + 1});;
    }
  };

  const handlePrevious = () => {
    if (bookingProcessStepsTracker?.currentStep > 0) dispatch({type:TRACK_STEPPER_STEPS, data: bookingProcessStepsTracker?.currentStep - 1});
  };

  




      // validation logic here
  const validationLogic = (currentStep) => {
    let isError = false;
    let currentStepFormField = stepperFormData[currentStep]?.formField;
    let currentStepFormFieldErrorHandling = stepperFormDataErrorHandling[currentStep]?.formField;
    for (let key in currentStepFormField){
      if(currentStepFormField.hasOwnProperty(key)){
        let subObject = currentStepFormField[key];
        let ifFormFieldEmpty = requiredFieldStepperValidation(subObject?.id, subObject?.value);
        if(ifFormFieldEmpty){
          let errorObject = {key:key, value:{
            isError:true,
            errorToDisplay:currentStepFormFieldErrorHandling[key]?.errorTypes?.requiredField?.errorMessage,
          }, step:currentStep}
          dispatch({type:STEPPER_FORMDATA_ERROR_HANDLING, data:errorObject})
          isError = true
        }else{
          isError = false
        }
      }
    } 
    return isError;
  }

  const performValidation = () => {
    let ifErrorDetected = false;
    if(bookingProcessStepsTracker?.currentStep === 0){
      ifErrorDetected = validationLogic("step1")
    }
    if(bookingProcessStepsTracker?.currentStep === 1){
      ifErrorDetected = validationLogic("step2")
    }
    if(bookingProcessStepsTracker?.currentStep === 2){
      ifErrorDetected =validationLogic("step3")
    }
    if(bookingProcessStepsTracker?.currentStep === 3){
      ifErrorDetected = validationLogic("step4")
    }
    return ifErrorDetected;
  }

  return (
    <div className="stepper-container">
      {/* Step Indicators */}
      {/* <div className="stepper">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`step ${
              index === currentStep
                ? "active"
                : index < currentStep
                ? "completed"
                : "inactive"
            }`}
          >
            <div className="step-number">{index + 1}</div>
            <div className="step-label">{step}</div>
          </div>
        ))}
      </div> */}

      {/* Step Content */}
      <div className="step-content">{stepContent[bookingProcessStepsTracker?.currentStep]}</div>

      {/* Navigation Buttons */}
      <div className="buttons_two_prev_next">
        <button onClick={handlePrevious} disabled={bookingProcessStepsTracker?.currentStep === 0}>
          Previous
        </button>
        <button onClick={handleNext} disabled={bookingProcessStepsTracker?.currentStep === bookingProcessStepsTracker?.totalStep - 1}>
          Next
        </button>
      </div>
    </div>
  );
}
