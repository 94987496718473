import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleChangeUserdata,
  handleStepperFormFieldPayload,
  saveUserDetails,
} from "../statemanagement/common/action";
import "./userdetailspopup.css";
import _ from "lodash";

const UserDetailsPopup = ({ details }) => {
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [mailCheckBox, setMailCheckBox] = useState(false);
  const [agreeCheckBox, setAgreeCheckBox] = useState(false);

  const { userData, productById, stepperFormData } = useSelector(

    (state) => state.common
  );
  console.log('stepperFormData', stepperFormData)

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    let price = !productById?.is_roundtrip ? productById?.oneway?.product_price : productById?.roundtrip?.product_price
    if (productById?.is_roundtrip) {
      if (!_?.isEmpty(price)) {
        let adultPirce = price?.map((i) => {
          if (i.name === "adult") {
            return i.round_ride * stepperFormData?.step3?.formField?.totalAdults?.value;
          } else {
            return i.round_ride * stepperFormData?.step3?.formField?.totalChildren?.value;
          }
        });
        const total = adultPirce?.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );

        setTotalPrice(total);
      } else {
        let adultPirce = price?.map((i) => {
          if (i.name === "adult") {
            return i.single_ride * stepperFormData?.step3?.formField?.totaladult?.value;
          } else {
            return i.single_ride * stepperFormData?.step3?.formField?.totalchild?.value;
          }
        });
        const total = adultPirce.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
        setTotalPrice(total);
      }
    }
  }, [stepperFormData]);

  //total price adult and child if round trip and it single and

  // Combine first and last name for display

  // Handle gender change
  const handleGenderChange = (e) => {
    dispatch(
      handleChangeUserdata({
        ...userData,
        gender: e.target.value,
      })
    );
  };

  function storeUserInfo(e){
    const {name , value ,id} = e.target
    console.log(name,value,id)
    dispatch(handleStepperFormFieldPayload(name, id, value, "step4"));
  }
  const validateForm = () => {
    let formErrors = {};

    if (!userData.first_name || !userData.last_name) {
      formErrors.name = "Full name is required";
    }

    if (!userData.age) {
      formErrors.age = "Age is required";
    }

    if (!userData.mobile || !/^\d{10}$/.test(userData.mobile)) {
      formErrors.mobile = "Valid contact number is required";
    }

    if (!userData.email || !/\S+@\S+\.\S+/.test(userData.email)) {
      formErrors.email = "Valid email is required";
    }

    if (!userData.gender) {
      formErrors.gender = "Gender is required";
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate all fields
    if (validateForm()) {
      dispatch(saveUserDetails());
    }
  };

  // if (!isOpen) return null;

  return (
    <div className="passenger_details">
      <div className="mb-4 ">
        <label className="block text-sm font-semibold text-[#212121]">
          First Name
        </label>
        <input
          type="text"
          id="1"
          name="firstName"
          value={stepperFormData?.step4?.formField?.firstName?.value}
          onChange={storeUserInfo}
          className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md"
          placeholder="Enter name"
          required
        />
        {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
      </div>
      <div className="mb-4 ">
        <label className="block text-sm font-semibold text-[#212121]">
          Last Name
        </label>
        <input
          type="text"
           id="2"
          name="lastName"
          value={stepperFormData?.step4?.formField?.lastName?.value}
          onChange={storeUserInfo}
          className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md"
          placeholder="Enter name"
          required
        />
        {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold text-[#212121]">
          Gender
        </label>
        <div className="mt-1 flex items-center space-x-4">
          <label>
            <input
             id="3"
              type="radio"
              name="gender"
              value="Male"
              checked={stepperFormData?.step4?.formField?.gender?.value === "Male"}
              onChange={storeUserInfo}
              className="mr-2"
              required
            />
            Male
          </label>
          <label>
            <input
             id="3"
              type="radio"
              name="gender"
                value="Female"
              checked={stepperFormData?.step4?.formField?.gender?.value === "Female"}
              onChange={storeUserInfo}
              className="mr-2"
              required
            />
            Female
          </label>
          {errors.gender && (
            <p className="text-red-500 text-sm">{errors.gender}</p>
          )}
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold text-[#212121]">
          Age
        </label>
        <input
         id="4"
          type="number"
          name="age"
          value={stepperFormData?.step4?.formField?.age?.value}
          onChange={storeUserInfo}
          className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md"
          placeholder="Enter age"
          required
        />
        {errors.age && <p className="text-red-500 text-sm">{errors.age}</p>}
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold text-[#212121]">
          Contact no.
        </label>
        <input
         id="5"
          type="tel"
          name="phoneNo"
          value={stepperFormData?.step4?.formField?.phoneNo?.value}
          maxLength={10}
          onChange={storeUserInfo}
          className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md"
          placeholder="Enter number"
          required
        />
        {errors.mobile && (
          <p className="text-red-500 text-sm">{errors.mobile}</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-sm font-semibold text-[#212121]">
          Email
        </label>
        <input
         id="6"
          type="email"
          name="email"
          value={stepperFormData?.step4?.formField?.email?.value}
          onChange={storeUserInfo}
          className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md"
          placeholder="Enter email"
          required
        />
        {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
      </div>
      <div className="mb-4 text-end">
        <button
          type="button"
          // onClick={isOpenPopup}
          className="text-[#212121] font-semibold"
        >
          + Add passenger details
        </button>
      </div>
      <div className="mb-4">
        <label className="inline-flex items-start text-[#212121] font-normal">
          <input
            type="checkbox"
            className="mr-2 mt-1"
            checked={mailCheckBox}
            onClick={() => setMailCheckBox(!mailCheckBox)}
          />
          Sent request on your email
        </label>
      </div>
      <div className="mb-4">
        <label className="inline-flex items-start text-[#212121] font-normal">
          <input
            type="checkbox"
            className="mr-2 mt-1"
            required
            checked={agreeCheckBox}
            onClick={() => setAgreeCheckBox(!agreeCheckBox)}
          />
          By clicking on proceed, I agree that I have read and understood <br />
          the TnCs and the Privacy Policy
        </label>
      </div>
      <div className="flex justify-between items-center">
        <div className="">
          <p className="text-lg font-bold">Total Amount : ${totalPrice}</p>
        </div>
        <button
          onClick={handleSubmit}
          type="submit"
          className="bg-gradiant-yellow2 text-[#212121] font-semibold capitalize rounded-md py-2 px-6"
        >
          Proceed to Pay
        </button>
      </div>
    </div>
  );
};
export default UserDetailsPopup;
