import { axiosInstance } from '../../api/apiConfig';
import { FETCH_CATEGORY, FETCH_FAQ, FETCH_HOTEL, FETCH_OFFERS, FETCH_PARTNERS, FETCH_Product, FROM_LOCATION, GENERAL_SETTING, PRICE_DETAILS, SAVE_CONTACTUS, SAVE_USERS, TO_LOCATION } from '../../helper/APIConstant';
import { CATEGORY_BYID, CATEGORY_LIST, COMMON_LOADER, FAQ_LIST, FROM_LOCATIONS, GENERAL_SETTING_LIST, HANDLE_USER_DATA, HOTEL_LIST, OFFERS_LIST, PARTNERS_LIST, SELECT_TIMER_POPUP, PRODUCT_BYID, PRODUCT_LIST, TO_LOCATIONS, STORE_STEPPER_FORMDATA } from './types';

// Thunks
export const fetchProducts = () => {
  return async (dispatch) => {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(FETCH_Product)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: PRODUCT_LIST, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        
      });
  };
};

export const fetchProductsById = (id) => {
 
  return async (dispatch) => {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(`${FETCH_Product}/${id}`)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: PRODUCT_BYID, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        
      });
  };
};


export const fetchFaqData = () => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(FETCH_FAQ)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: FAQ_LIST, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        
      });
  };
};
export const getCategory = () => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(FETCH_CATEGORY)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: CATEGORY_LIST, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        
      });
  };
};
export const getCategoryById = (id) => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(`${FETCH_CATEGORY}/${id}`)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: CATEGORY_BYID, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        
      });
  };
};
export const getPartnerlist = () => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(FETCH_PARTNERS)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: PARTNERS_LIST, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        
      });
  };
};
export const getToLocation = (id,fromLocation) => {
  return async function (dispatch) {
    // dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(`${TO_LOCATION}${id}&from_id=${fromLocation}`)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: TO_LOCATIONS, response: res?.data?.data });
       
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        
      });
  };
};
export const getFromLocation = (id) => {
  return async function (dispatch) {
    // dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(`${FROM_LOCATION}${id}`)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
          dispatch({ type: FROM_LOCATIONS, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        
      });
  };
};
export const getPriceDetails = (from_id,to_id,product_id) => {

  
  return async function (dispatch) {
    const params = {
    
      from_id: from_id, // Dynamic or fixed value
      to_id: to_id,    // Dynamic or fixed value
      product_id: product_id, // Dynamic or fixed value
    };
    
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(PRICE_DETAILS,{params})
      .then((res) => {
        setTimeout(()=>{
          dispatch({ type: COMMON_LOADER, isLoading: false });
          dispatch({ type: PRODUCT_BYID, response: res?.data?.data });
          dispatch({ type: SELECT_TIMER_POPUP, data: true });
        },3000)
       
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        
      });
  };
};
export const getGeneralSetting = () => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(GENERAL_SETTING)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: GENERAL_SETTING_LIST, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        
      });
  };
};
export const getHotelList = () => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(FETCH_HOTEL)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: HOTEL_LIST, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        
      });
  };
};
export const getOffersList = () => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(FETCH_OFFERS)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: OFFERS_LIST, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        
      });
  };
};
// export const addProduct = (product) => {
//   return async (dispatch) => {
//     dispatch(addProductRequest());
//     try {
//       const response = await axios.post('/api/products', product);
//       dispatch(addProductSuccess(response.data));
//     } catch (error) {
//       dispatch(addProductFailure(error.message));
//     }
//   };
// };
export const saveContactus = (data) => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .post(SAVE_CONTACTUS, data)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        // dispatch({ type: FROM_LOCATIONS, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        
      });
  };
};
export const saveUserDetails = () => {
  return async function (dispatch, getState) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    let data = getState().common?.userData;
    // let obj = {
    //   ...data,

    // };
    axiosInstance
      .post(SAVE_USERS, data)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        // dispatch({ type: FROM_LOCATIONS, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        
      });
  };
};
// export const saveCountry = (data, onClose) => {
//   return async function (dispatch) {
//     dispatch({ type: ADD_LOADER, isLoading: true });
//     axiosInstanceAuth(2) //Add action type
//       .post(REGIONAL_SETUP.SAVE_COUNTRY, data)
//       .then((res) => {
//         dispatch({ type: ADD_LOADER, isLoading: false });
//         if (res?.data?.responseCode === 0) {
//           dispatch({
//             type: SET_SUCCESS_FOR_MODAL,
//             success: res?.data?.message,
//           });
//           dispatch(fetchCountries());
//           onClose();
//         } else {
//           dispatch({ type: SET_ERROR_FOR_MODAL, error: res?.data?.message });
//         }
//       })
//       .catch((err) => {
//         dispatch({ type: ADD_LOADER, isLoading: false });
//         unAuthorizedUser(err, dispatch);
//       });
//   };
// };



// onChange of salesQuotation
export const handleChangeUserdata = (data) => {
  return async function (dispatch) {
    dispatch({ type: HANDLE_USER_DATA, data: data });
  };
};




//comman function to store stepper data 
// generating payload for api
export const handleStepperFormFieldPayload = (key, id, value, currentStep) => {
  return async function (dispatch) {
  let formFieldPayload = {
    key:key, value:{id:id, value:value}, step:currentStep
  }
  dispatch({type:STORE_STEPPER_FORMDATA, data:formFieldPayload})
}
}