import React, { useState } from "react";
import { BsClock } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import { MdClose } from "react-icons/md";
import { SlLocationPin } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProductsById,
  getFromLocation,
  getPriceDetails,
  getToLocation,
} from "../statemanagement/common/action";
import DatePicker from "react-datepicker"; // Import DatePicker
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker styles
import "./booking.css";


const Bookingcomponent = ({ trip = true }) => {
  let dispatch = useDispatch();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const { toLocationList, productById, productList, fromLocationList } =
 
    useSelector((state) => state.common);
    const [productDetails,setProductDetails] = useState({
        product:"",
        fromLocation:"",
        toLocation:""
    })

let productData = productById?.oneway?.session
  const fromDate =productData?.from_date;
  const untilDate =productData?.until_date;


  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  function handleProductById(e) {
    let {name,value} = e.target;
    dispatch(getFromLocation(value));
    setProductDetails((prev)=>({
        ...prev,[name]:value
    }))

  }

  const isDateInRange = (date) => {
    const highlightedFromDate = new Date(fromDate);
    const highlightedUntilDate = new Date(untilDate);
    return date >= highlightedFromDate && date <= highlightedUntilDate;
  };

  const highlightDate = (date) => {
    if (date.toDateString() === new Date().toDateString()) {
      return "highlight-today"; // Class for current date
    }
    if (isDateInRange(date)) {
      return "highlight-range"; // Class for highlighted range
    }
    return undefined;
  };
  const handleLocation = (e) => {
    const { name, value } = e.target;
    if (name === "fromLocation") {
        setProductDetails((prev)=> ({
      ...prev,
      [name]: value,
      toLocation:""
    }))
      dispatch(getToLocation(productDetails?.product, value));
    }else{
        dispatch(getPriceDetails(productDetails?.fromLocation,value));
        setProductDetails((prev)=> ({
            ...prev,
            [name]: value,
          }))
    }
 
  };
  const locations = [
    {id:1,label:"From",name:"fromLocation",placeholder:"Choose From Location",value:fromLocationList?.find((i) => i.id == productDetails?.fromLocation)?.name,lists:fromLocationList},
    {id:2,label:"To",name:"toLocation",placeholder:"Choose To Location",value:toLocationList?.find((i) => i.id == productDetails?.toLocation)?.name,lists:toLocationList}
]


function handleSearch(){
   
}


  return (
    <div className="w-[100%] mx-5 lg:mx-auto absolute -bottom-20 lg:-bottom-20 right-0 left-0 lg:flex justify-center ">
      <div className="bg-white p-6 rounded-[14px] lg:rounded-lg shadow-2xl lg:shadow-xl ">
        <div className="w-full flex flex-col lg:flex-row items-center gap-4">
          {trip && (
            <div className="w-full lg:mb-4">
              <select
              name="product"
                onChange={handleProductById}
                className="block w-full bg-[#ECECEC] border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option>Select service</option>
                {productList.map((i) => (
                  <option key={i.id} value={i.id}>
                    {i?.name}
                  </option>
                ))}
              </select>
            </div>
          )}
          {productData?.is_roundtrip ? <div className={`${trip ? "w-full" : "w-2/4"} hidden lg:flex mb-4`}>
                        <button className="w-1/2 text-center py-2 rounded-tl-lg rounded-bl-lg bg-gradiant-yellow4">One way trip</button>
                        <button className="w-1/2 text-center py-2 rounded-tr-lg rounded-br-lg bg-gray-200">Round trip</button>
                    </div> : null }
        </div>
        <div className="hidden lg:flex items-center justify-between gap-4 mb-4">
         {locations.map((i)=> <div>
            <label className="block text-sm font-semibold text-[#212121]">
              {i?.label}
            </label>
            <div className="relative flex bg-[#ECECEC] w-full p-1 justify-center items-center mt-1 border-transparent  rounded-md">
              <SlLocationPin className="  text-[#FB8500]" />

              <select
                  value={i?.value || null}
                name={i.name}
                className="p-2 bg-[#ECECEC] w-[200px]"
                onChange={handleLocation}
              >
                <option defaultValue={"choose size"}>
                  {i?.value || "Choose From Location"}
                  Choose {i?.label} Location
                </option>
                {i?.lists.map((i) => (
                  <option key={i.id} value={i.id}>
                    {i.name}
                  </option>
                ))}
              </select>
            </div>
          </div>)
          }     
          <div className="text-center mt-4" onClick={handleSearch}>
            <button className="bg-[#FFB703] shadow-md shadow-[#FFB703] text-black text-base p-2 rounded-full">
              <CiSearch />
            </button>
          </div>
        </div>
      </div>
      <PopupForm isOpen={isPopupOpen} onClose={togglePopup} />
    </div>
  );
};

export default Bookingcomponent;

export const PopupForm = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="relative w-[320px] sm:w-[480px] lg:w-[640px] overflow-y-scroll bg-popup-bg bg-center bg-no-repeat p-6 rounded-lg shadow-lg">
        <h2 className="text-lg text-[#212121] font-semibold mb-4">
          Fill up details
        </h2>
        <div className="absolute top-5 right-5 cursor-pointer">
          <MdClose size={24} onClick={onClose} />
        </div>
        <form>
          <div className="space-y-4 lg:space-y-4">
            <div>
              <label className="block text-sm font-semibold text-[#212121]">
                Depart
              </label>
              <DatePicker
                selected={new Date()} // Example, you can handle this differently
                onChange={() => {}} // Placeholder for logic
                className="mt-1 p-2 border-transparent bg-[#ECECEC] placeholder:text-xs placeholder:text-[#212121] w-full rounded-md"
                placeholderText="Select a date"
              />
              <BsClock className="absolute mt-0.5 bg-clip-text top-[50%] left-2 translate-y-[-50%] text-[#FB8500]" />
            </div>
            {/* Add other fields similar to the above */}
          </div>
          <div className="mt-4">
            <button
              type="submit"
              className="w-full bg-gradiant-yellow5 font-semibold text-[17px] text-[#212121] py-2 px-4 rounded-md focus:outline-none"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
