import React, { useState } from "react";
import './dynamicform.css'

export const DynamicForm = ({ formData }) => {
  // Initialize form state
  const initialFormState = formData.reduce((acc, field) => {
    acc[field.fieldname] = ""; // Set initial value for each field
    return acc;
  }, {});

  const [formState, setFormState] = useState(initialFormState);
  const [errors, setErrors] = useState({}); // For storing validation errors

  // Handle input changes
  const handleChange = (e, fieldname) => {
    const { value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [fieldname]: value,
    }));
  };

  // Validate the form fields
  const validateForm = () => {
    const newErrors = {};

    formData.forEach((field) => {
      const { fieldname, required, fieldtype, maxlength } = field;
      const value = formState[fieldname];

      if (required && !value) {
        newErrors[fieldname] = `${field.displayname} is required.`;
      }

      if (fieldtype === "email" && value && !/\S+@\S+\.\S+/.test(value)) {
        newErrors[fieldname] = "Please enter a valid email address.";
      }

      if (fieldtype === "mobile" && value && !/^\d{10}$/.test(value)) {
        newErrors[fieldname] = "Please enter a valid 10-digit phone number.";
      }

      if (maxlength && value && value.length > maxlength) {
        newErrors[fieldname] = `${field.displayname} cannot be longer than ${maxlength} characters.`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
    } else {
    }
  };

  return (
    <form className="form_dynamic" onSubmit={handleSubmit}>
      {formData.map((field) => {
        const { fieldtype, displayname, fieldname, required, lookupdata, maxlength } = field;

        return (
          <div key={field._id} style={{ marginBottom: "1rem" }}>
            <label htmlFor={fieldname}>
              {displayname} {required && <span style={{ color: "red" }}>*</span>}
            </label>
            {fieldtype === "text" && (
              <input
                type="text"
                id={fieldname}
                name={fieldname}
                value={formState[fieldname]}
                onChange={(e) => handleChange(e, fieldname)}
                required={required}
                maxLength={maxlength || undefined}
              />
            )}
            {fieldtype === "datepicker" && (
              <input
                type="date"
                id={fieldname}
                name={fieldname}
                value={formState[fieldname]}
                onChange={(e) => handleChange(e, fieldname)}
                required={required}
              />
            )}
            {fieldtype === "radio" && (
              <div>
                {lookupdata.map((option) => (
                  <label key={option.value}>
                    <input
                      type="radio"
                      name={fieldname}
                      value={option.value}
                      checked={formState[fieldname] === option.value}
                      onChange={(e) => handleChange(e, fieldname)}
                      required={required}
                    />
                    {option.key}
                  </label>
                ))}
              </div>
            )}
            {fieldtype === "mobile" && (
              <input
                type="tel"
                id={fieldname}
                name={fieldname}
                value={formState[fieldname]}
                onChange={(e) => handleChange(e, fieldname)}
                required={required}
                maxLength={maxlength || 10}
              />
            )}
            {fieldtype === "primaryemail" && (
              <input
                type="email"
                id={fieldname}
                name={fieldname}
                value={formState[fieldname]}
                onChange={(e) => handleChange(e, fieldname)}
                required={required}
              />
            )}

            {/* Display error message if any */}
            {errors[fieldname] && (
              <div style={{ color: "red", fontSize: "12px" }}>
                {errors[fieldname]}
              </div>
            )}
          </div>
        );
      })}
      <button type="submit">Submit</button>
    </form>
  );
};

// Example Usage
