import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import "swiper/swiper-bundle.css"; // Make sure to import Swiper styles
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import Layout from "./component/Layout";
import Home from "./pages/home/Home";
import AboutUs from "./pages/aboutUs/AboutUs";
import ContactUs from "./pages/contactUs/ContactUs";
import Booking from "./pages/booking/Booking";
import Product from "./pages/product/Product";
import AllDetailsPage from "./pages/AllDetails/AllDetailsPage";




function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route element={<Layout />}>
          
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/timetable-booking" element={<Booking />} />
            <Route path="/product" element={<Product />} />
            <Route path="/product/:id" element={<Product />} />
            <Route path="/allDetailsPage" element={<AllDetailsPage />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
