import { toLower } from "lodash";
import {
  BOOKING_DATA,
  CATEGORY_BYID,
  CATEGORY_LIST,
  COMMON_LOADER,
  FAQ_LIST,
  FROM_LOCATIONS,
  GENERAL_SETTING_LIST,
  HANDLE_USER_DATA,
  HOTEL_LIST,
  OFFERS_LIST,
  PARTNERS_LIST,
  PASSENGER_DETAILS_POPUP,
  SELECT_TIMER_POPUP,
  PRODUCT_BYID,
  PRODUCT_LIST,
  TO_LOCATIONS,
  USER_DETAILS_POPUP,
  ADULT_CHILD_POPUP,
  LOCATION_POPUP,
  HANDLE_HOME_PAGE_MODAL,
  HANDLE_PRODUCT_PAGE_MODAL,
  HANDLE_SCHEDULE_PAGE_MODAL,
  USER_SELECTED_ROUTE,
  STORE_STEPPER_FORMDATA,
  TRACK_STEPPER_STEPS,
  STEPPER_FORMDATA_ERROR_HANDLING,
  BOOKING_INFO_DETAILS
} from "./types";

const initialUserState = {
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  age: "",
  gender: "",
};

const userSelectedRoute = {
  fromLocation:{id:"", name:""},
  toLocation:{id:"", name:""},
  product:"",
  
}

const bookingProcessStepsTracker = {
  currentStep:0,
  totalStep:4
}

const stepperFormData = {
  step1:{
    formType:"Location Info",
    formField:{ fromLocation:{
      id:"", value:"", validationType:['requiredFieldStepperValidation']
    }, toLocation:{
      id:"", value:"", validationType:['requiredFieldStepperValidation']
    }, tripType:{
      id:"", value:"", validationType:['requiredFieldStepperValidation']
    }},
    formValidationCondition: "All Fields required"
  },
  step2:{
    formType:"Schedule Info",
    formField:{
      departureDate:{
        id:"", value:"", validationType:['requiredFieldStepperValidation', 'departureDateGreaterThanOrEqualToTodayDate']
      },
      departureTime:{
        id:"", value:"", validationType:['requiredFieldStepperValidation','departureTimeIsNotGreaterThanPastTimeForToday']
      },
      returnDate:{
        id:"", value:"", validationType:['requiredFieldStepperValidation', 'returnDateNotSmallerThanDepartureDate']
      },
      returnTime:{
        id:"", value:"", validationType:['requiredFieldStepperValidation', 'returnDateAndTimeNotSmallerThanDepartureDateAndTime']
      },
    },
    formValidationCondition: "If tripType is one way only departure date and time should be there and if tripType is round trip then everything should be required "
  },
  step3:{
    formType:"Travelers Info",
    formField:{
    totalAdults:{
      id:"", value:"", validationType:['requiredFieldStepperValidation', 'totalPassengersNotGreaterThanSessionAvailabilityValidation']
    },
    totalChildren:{
      id:"", value:"", validationType:['requiredFieldStepperValidation', 'childWithNoAdultValidation']
    }
  },
  formValidationCondition: "Total adults and children should be greater than 0 and should not be greater then total session availability"
  },
  step4:{
    formType:"Travelers Info",
    formField:{
      firstName:{
        id:"", value:"", validationType:['requiredFieldStepperValidation','NoSpecialCharacterAndSpacesValidation'
        ]
      },
      lastName:{
        id:"", value:"", validationType:['requiredFieldStepperValidation','NoSpecialCharacterAndSpacesValidation']
      },
      email:{
        id:"", value:"", validationType:['requiredFieldStepperValidation','emailValidation']
      },
      phoneNo:{
        id:"", value:"", validationType:['requiredFieldStepperValidation','phoneNoLengthValidation']
      }
    }
  }
}


const bookingInfoDetails ={
  totalAvailability:0,
  isSelectRoundTrip:false
}


const stepperFormDataErrorHandling = {
  step1: {
    formField:{
      fromLocation : {
        isError:false,
        errorToDisplay:"",
        errorTypes:{
          requiredField:{
            errorMessage:"Please Select pickup location"
          }
        },
      },toLocation:{
        isError:false,
        errorToDisplay:"",
        errorTypes:{
          requiredField:{
            errorMessage:"Please Select destination location"
          }
        },
      },tripType:{
        isError:false,
        errorToDisplay:"",
        errorTypes:{
          requiredField:{
            errorMessage:"You must select a trip type ('One Way' or 'Round Trip') before continuing."
          }
        },
      }
    }
  },step2:{
    formField:{
      departureDate:{
        isError:false,
        errorToDisplay:"",
        errorTypes:{
          requiredField:{
            errorMessage:"You must select a departure date before continuing."
          }
        },
      },departureTime:{
        isError:false,
        errorToDisplay:"",
        errorTypes:{
          requiredField:{
            errorMessage:"You must select a departure time before continuing."
          }
        },
      },returnDate:{
        isError:false,
        errorToDisplay:"",
        errorTypes:{
          requiredField:{
            errorMessage:"You must select a return date before continuing."
          }
        },
      },returnTime:{
        isError:false,
        errorToDisplay:"",
        errorTypes:{
          requiredField:{
            errorMessage:"You must select a return time before continuing."
          }
        },
      }
    }
  },step3:{
    formField:{
      totalAdults:{
        isError:false,
        errorToDisplay:"",
        errorTypes:{
          requiredField:{
            errorMessage:"Please add at least one passenger to proceed."
          }
        },  
      },totalChildren:{
        isError:false,
        errorToDisplay:"",
        errorTypes:{
          requiredField:{
            errorMessage:"At least one adult must be included when selecting children as travelers"
          }
        },
      }
    }
  },step4:{
    formField:{
      firstName:{
        isError:false,
        errorToDisplay:"",
        errorTypes:{
          requiredField:{
            errorMessage:"Please enter the first name"
          },
          validationError:{
            errorMessage:"No special character or number is allowed"
          }
        },
      },lastName:{
        isError:false,
        errorToDisplay:"",
        errorTypes:{
          requiredField:{
            errorMessage:"Please enter the last name"
          },
          validationError:{
            errorMessage:"No special character or number is allowed"
          }
        },
      },email:{
        isError:false,
        errorToDisplay:"",
        errorTypes:{
          requiredField:{
            errorMessage:"Please enter valid email address"
          },
          validationError:{
            errorMessage:"Please enter valid email address"
          }
        },
      },phoneNo:{
        isError:false,
        errorToDisplay:"",
        errorTypes:{
          requiredField:{
            errorMessage:"Please enter the phone no"
          },
          validationError:{
            errorMessage:"Length cannot be greater than 10"
          }
        },
        gender:{
          isError:false,
          errorToDisplay:"",
          errorTypes:{
            requiredField:{
              errorMessage:"Please Select the Gender"
            },
            validationError:{
              errorMessage:"lease Select the Gender"
            }
          },
        }
      }
    }
  }
}




const initialState = {
  commonLoader: false,
  faqdatalist: [],
  productList: [],
  categoryList: [],
  partnerList: [],
  toLocationList: [],
  fromLocationList: [],
  generalSettingList: [],
  hotelList: [],
  OffersList: [],
  error: "",
  userData: initialUserState,
  productById: {},
  categoryById: {},
  selectTimerPopup: false,
  passDetailsPopup: false,
  bookingData: {},
  userDetailsPopup:false,
  adultChildPopup:false,
  locationPopup:false,
  isHomePageModalOpen:false,
  isProductPageModalOpen:false,
  isSchedulePageModalOpen:false,
  userSelectedRoute:userSelectedRoute,
  bookingProcessStepsTracker:bookingProcessStepsTracker,
  stepperFormData:stepperFormData,
  stepperFormDataErrorHandling:stepperFormDataErrorHandling,
  bookingInfoDetails:bookingInfoDetails
  
};

export default function commonReducer(state = initialState, action) {

  switch (action.type) {
    case COMMON_LOADER:
      return {
        ...state,
        commonLoader: action.isLoading,
      };
    case BOOKING_INFO_DETAILS:
      return {
        ...state,
        bookingInfoDetails: action.data,
      };
    case FAQ_LIST:
      return {
        ...state,
        faqdatalist: action?.response,
      };
    case PRODUCT_LIST:
      return {
        ...state,
        productList: action?.response,
      };
    case PRODUCT_BYID:
      return {
        ...state,
        productById: action?.response,
      };
    case CATEGORY_LIST:
      return {
        ...state,
        categoryList: action?.response,
      };
    case CATEGORY_BYID:
      return {
        ...state,
        categoryById: action?.response,
      };
    case PARTNERS_LIST:
      return {
        ...state,
        partnerList: action?.response,
      };
    case TO_LOCATIONS:
      return {
        ...state,
        toLocationList: action?.response,
      };
    case FROM_LOCATIONS:
      return {
        ...state,
        fromLocationList: action?.response,
      };
    case GENERAL_SETTING_LIST:
      return {
        ...state,
        generalSettingList: action?.response,
      };
    case HOTEL_LIST:
      return {
        ...state,
        hotelList: action?.response,
      };
    case OFFERS_LIST:
      return {
        ...state,
        OffersList: action?.response,
      };

    case HANDLE_USER_DATA:
      return {
        ...state,
        userData: action?.data,
      };
    case PASSENGER_DETAILS_POPUP:
      return {
        ...state,
        passDetailsPopup: action?.data,
      };
    case SELECT_TIMER_POPUP:
      return {
        ...state,
        selectTimerPopup: action?.data,
      };
    case BOOKING_DATA:
      return {
        ...state,
        bookingData: action?.data,
      };
    case USER_DETAILS_POPUP:
      return {
        ...state,
        userDetailsPopup: action?.data,
      };
    case ADULT_CHILD_POPUP:
      return {
        ...state,
        adultChildPopup: action?.data,
      };
  
    case LOCATION_POPUP:
      return {
        ...state,
        locationPopup: action?.data,
      };

    case HANDLE_HOME_PAGE_MODAL:
      return {
        ...state,
        isHomePageModalOpen: action?.data
      };

    case HANDLE_PRODUCT_PAGE_MODAL:
        return {
          ...state,
          isProductPageModalOpen: action?.data
    };
    case HANDLE_SCHEDULE_PAGE_MODAL:
        return {
          ...state,
          isSchedulePageModalOpen: action?.data
    };
    case USER_SELECTED_ROUTE:
      return{
        ...state,
        userSelectedRoute:action?.data
      };
    case TRACK_STEPPER_STEPS:
      return{
        ...state,
        bookingProcessStepsTracker: {
          ...state.bookingProcessStepsTracker,
          currentStep:action?.data
      }
    };  
    case STORE_STEPPER_FORMDATA:{

      return{
        ...state,
        stepperFormData: {
          ...state.stepperFormData,
          [action?.data?.step]:{
            ...state.stepperFormData[action?.data?.step],
            formField:{
              ...state.stepperFormData[action?.data?.step]?.formField,
              [action?.data?.key]:{
                ...state.stepperFormData[action?.data?.step]?.formField[action?.data?.key],
                  id:action?.data?.value?.id,
                  value:action?.data?.value?.value
              }
            }
          },
          }
        }
      };
    case STEPPER_FORMDATA_ERROR_HANDLING:{
  
      return{
        ...state,
        stepperFormDataErrorHandling: {
          ...state.stepperFormDataErrorHandling,
          [action?.data?.step]:{
            ...state.stepperFormDataErrorHandling[action?.data?.step],
            formField:{
              ...state.stepperFormDataErrorHandling[action?.data?.step]?.formField,
              [action?.data?.key]:{
                ...state.stepperFormDataErrorHandling[action?.data?.step]?.formField[action?.data?.key],
                  isError:action?.data?.value?.isError,errorToDisplay  :action?.data?.value?.errorToDisplay
              }
            }
          },
          }
        }
      };

    default:
      return state;
  }
}
