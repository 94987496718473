//defining Validation types
const requiredFieldStepperValidation = (value1, value2) =>{
    let isError = false;
    if(value1 === null || value1 === undefined || value1 === "" || value2 === null || value2 === undefined || value2 === ""){
            isError = true;// if any field is empty then it will be true
     }
     return isError;
  }

const requiredFieldFormValidation = (value) => {
    let isError = false;
    if(value === null || value === undefined || value === ""){
        isError = true;// if any field is empty then it will be true
    }
    return isError;
}

  const departureDateGreaterThanOrEqualToTodayDate = (departureDate) => {
    let isError = false;
    // Get today's date and set the time to midnight (00:00:00)
     const today = new Date();
     today.setHours(0, 0, 0, 0); // Set time to midnight for accurate comparison
 
     // Convert the departureDate to a timestamp
     const departureTimestamp = new Date(departureDate).getTime();
 
     // Compare departureDate with today's date
     if (departureTimestamp < today.getTime()) {
         isError = true; // Set error if departureDate is in the past
     }
 
     return isError; // Return the error status

}

    const departureTimeIsNotGreaterThanPastTimeForToday = (departureDate,departureTime) => {
        let isError = false;

        // Get today's date and set the time to midnight (00:00:00)
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to midnight for accurate comparison
    
        // Combine departureDate and departureTime into a single Date object
        const departureDateTime = new Date(`${departureDate}T${departureTime}`);
    
        // Check if the departureDate is today
        if (departureDateTime >= today && departureDateTime < new Date(today.getTime() + 24 * 60 * 60 * 1000)) {
            // If the departure time is in the past
            if (departureDateTime < new Date()) {
                isError = true; // Set error if departure time has already passed
            }
        }
    
        return isError; // Return the error status
    }

  const returnDateNotSmallerThanDepartureDate = (departureDate, returnDate) => {
    let isError = false;
    // Convert the dates to timestamps for comparison
    const departureTimestamp = new Date(departureDate).getTime();
    const returnTimestamp = new Date(returnDate).getTime();

    // Check if returnDate is smaller than departureDate
    if (returnTimestamp < departureTimestamp) {
     isError = true; // Set error if returnDate is smaller
    }
    return isError; // Return the error status    

  }

  const returnDateAndTimeNotSmallerThanDepartureDateAndTime = (departureDate, departureTime, returnDate, returnTime) => {
    let isError = false;
    // Combine departureDate and departureTime into a single Date object
    const departureDateTime = new Date(`${departureDate}T${departureTime}`);
    
    // Combine returnDate and returnTime into a single Date object
    const returnDateTime = new Date(`${returnDate}T${returnTime}`);

    // Check if returnDateTime is less than or equal to departureDateTime
    if (returnDateTime <= departureDateTime) {
    isError = true; // Set error if returnDate and time is less than or equal to departureDate and time
    }

    return isError; // Return the error status

  }

  const totalPassengersNotGreaterThanSessionAvailabilityValidation = (totalPassengers,sessionAvailability) => {
    let isError = false;

    // Check if totalPassengers is greater than sessionAvailability
    if (totalPassengers > sessionAvailability) {
        isError = true; // Set error if totalPassengers exceeds sessionAvailability
    }

    return isError; // Return the error status
  }

  const childWithNoAdultValidation = (adultPassengers, childPassengers) => {
    let isError = false;    
    // Check if there are child passengers and no adult passengers
    if (childPassengers > 0 && adultPassengers === 0) {
      isError = true; // Set error if there are child passengers but no adult passengers
    } 
    return isError; // Return the error status
  }

  const noSpecialCharacterAndSpacesValidation = (inputField) => {
    let isError = false;
    const regex = /^[a-zA-Z0-9]*$/;//regular expression string for alphanumeric characters only
    //check if inputField contains any special characters or spaces
    if(!regex.test(inputField)){
        isError = true; // Set error if inputField contains special characters or spaces
    }
    return isError; // Return the error status
  }

  const emailValidation = (inputField) => {
    let isError = false;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;// regular expression string for email validation
    //check if inputField contains any special characters or spaces
    if(!regex.test(inputField)){
        isError = true; // Set error if inputField is not a valid email
    }
    return isError;
  }

  const phoneNoLengthValidation = (inputField) => {
    let isError = false;

    // Regular expression for validating international phone numbers
    const regex = /^\+?[1-9]\d{0,14}$/; // Allows optional '+' followed by 1-15 digits

    // Check if inputField matches the regex
    if (!regex.test(inputField)) {
        isError = true; // Set error if inputField is not a valid phone number
    }

    return isError; // Return the error status
  }


  export {requiredFieldStepperValidation,requiredFieldFormValidation,departureDateGreaterThanOrEqualToTodayDate,
departureTimeIsNotGreaterThanPastTimeForToday,returnDateNotSmallerThanDepartureDate, returnDateAndTimeNotSmallerThanDepartureDateAndTime,totalPassengersNotGreaterThanSessionAvailabilityValidation,childWithNoAdultValidation, noSpecialCharacterAndSpacesValidation, emailValidation, phoneNoLengthValidation};