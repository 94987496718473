import React, { useEffect, useState } from "react";
import "./adultchildmodal.css";
import { useSelector, useDispatch } from "react-redux";
import { handleStepperFormFieldPayload } from "../../../statemanagement/common/action";

export default function AdultChildModal(props) {
  const { productById, bookingData, bookingInfoDetails } = useSelector(
    (state) => state?.common
  );

  const dispatch = useDispatch();

  const [childSize, setChildSize] = useState([]);
  const [adultSize, setAdultSize] = useState([]);
  const [ticket, setTicket] = useState({});
  const [totalTicket, setTotalTicket] = useState();

  useEffect(() => {
    // if(bookingData?.isreturn){
    let availability = bookingInfoDetails?.totalAvailability;
    const initialSize = Array.from({ length: availability }, (_, i) => i + 1);
    setChildSize(initialSize);
    setAdultSize(initialSize);
    setTotalTicket(availability);
    // }else{
    //   let availability = bookingInfoDetails?.availability;
    //     const initialSize = Array.from({ length: availability }, (_, i) => i + 1);
    //     setChildSize(initialSize);
    //     setAdultSize(initialSize);
    //     setTotalTicket(availability);
    // }
  }, [bookingInfoDetails?.totalAvailability]);
  // Update available child and adult size options based on ticket selection
  useEffect(() => {
    if (ticket.adult !== undefined) {
      const totalAdultTicket = totalTicket - ticket.adult;
      setChildSize(Array.from({ length: totalAdultTicket }, (_, i) => i + 1));
    }
    if (ticket.child !== undefined) {
      const totalChildTicket = totalTicket - ticket.child;
      setAdultSize(Array.from({ length: totalChildTicket }, (_, i) => i + 1));
    }
  }, [ticket]);

  let passengers = productById?.oneway?.product_price;

  const handleUserInfo = (e, value) => {
    if (e?.name === "adult") {
      dispatch(
        handleStepperFormFieldPayload("totalAdults", e.id, value, "step3")
      );
      setTicket({ ...ticket, [e?.name]: Number(value) });
    } else {
      dispatch(
        handleStepperFormFieldPayload("totalChildren", e.id, value, "step3")
      );
      setTicket({ ...ticket, [e?.name]: Number(value) });
    }
  };

  return (
    <div className="adult_child_container">
      <h2>Traveller Details</h2>
      {passengers?.map((label) => (
        <div key={label} className="dropdown-group">
          <label htmlFor={label?.name.toLowerCase()}>
            {label?.name}{" "}
            <span>
              {productById?.is_roundtrip && bookingData?.trip === "round-trip"
                ? `Round Trip (${label.round_ride})`
                : `Single Trip (${label?.single_ride}) `}
            </span>
          </label>
          <select
            id={label?.name}
            name={label?.name}
            className="dropdown"
            onChange={(e) => handleUserInfo(label, e?.target.value)}
          >
            <option>
              {label.name === "adult" ? "Select No of Adult" : "Selct No Child"}
            </option>
            {label.name === "adult"
              ? adultSize.map((i) => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))
              : childSize.map((i) => (
                  <option key={i} value={i}>
                    {i}
                  </option>
                ))}
          </select>
        </div>
      ))}
      <button
        className="submit-button"
        onClick={() => props.handleAdultChildSubmit(ticket, passengers)}
      >
        Submit
      </button>
    </div>
  );
}
