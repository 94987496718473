import React, { useEffect, useState } from 'react'
import BookingModule from '../../component/BookingModule/BookingModule';


const HeroSection = ({ booking }) => {
  const [videoData, setVideoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
 
  useEffect(() => {
    // Fetch video data from the API
    const fetchVideoData = async () => {
      try {
        const response = await fetch('https://goldeneaglesbackend.developersquad.in/api/video');
        const result = await response.json();
        if (result.success) {
          setVideoData(result.data);
        } else {
          setError('Failed to fetch video data');
        }
      } catch (err) {
        setError('Error fetching video data');
      } finally {
        setLoading(false);
      }
    };

    fetchVideoData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const homeVideoUrl = videoData?.Home?.video_url;



  return (
    <section className='p-0 hero_section relative lg:max-w-7xl mx-auto overflow-visible'>
      <div className='hero_bg relative'>
        {homeVideoUrl && (
          <video
            autoPlay
            loop
            muted
            playsInline
            className="absolute top-0 left-0 w-full h-[85%] object-cover "
          >
            <source src={homeVideoUrl} type="video/mp4" />
          </video>
        )}
        <div className='hero_text text-center px-4 relative z-10'>
          <h2 className='lg:w-4/5 mx-auto'>
            Your reliable ride to NYC, airport transfers, and scenic Hoboken tours—where comfort meets convenience!
          </h2>
        </div>
      </div>

      {booking && <BookingModule />}

    
    </section>)
}

export default HeroSection;