import React from 'react'
// import { Hotel_img1, Hotel_img2, Hotel_img3, Hotel_img4 } from '../../helper/imageConstant'
import { SlLocationPin } from 'react-icons/sl'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { Navigation } from 'swiper/modules'

const HotelsSection = (props) => {
    const { data } = props

    // Function to generate the Google Maps URL
    const generateMapsUrl = (address) => {
        return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    };

    // Handler to open the Google Maps link in a new tab
    const openInNewTab = (url, e) => {
        e.preventDefault();
        window.open(url, '_blank', 'noopener,noreferrer');
    };
    return (
        <section className='max-w-7xl mx-auto mt-28 my-10 mb-5 lg:mt-28 lg:my-20'>
            <div className='text-center lg:mb-2'>
                <div className=''>
                    <h2 className='text-[32px] text-[#212121] font-semibold'>Hotels we serve</h2>
                    {/* <p className='text-base text-[#212121] font-normal'>Lorem ipsum dolor sit amet consectetur. </p> */}
                </div>
                <div className='lg:w-3/4 lg:mx-auto'>
                    <p className='text-base text-[#212121] font-normal'>Our shuttle service connects you to a selection of comfortable,
                        affordable, and commuter ready hotels, ensuring a seamless travel experience from the
                        moment you arrive. Each hotel offers a unique blend of comfort, luxury, and
                        convenience, making them perfect for business or leisure stays. </p>
                </div>
            </div>
            <div className='max-w-7xl mx-auto py-5 lg:py-10'>
                <div className='w-full'>
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={2}
                        loop={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                                loop: true
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 40,
                                loop: true
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                                loop: true
                            },
                        }}
                        className="mySwiper"

                    >
                        {data?.map((i, j) => (
                            <SwiperSlide key={j} className=''>
                                <div className='w-full space-y-2'>
                                    <img src={i?.image} alt='hotel' className='w-full mx-auto object-fill max-h-[120px] lg:max-h-[150px] rounded-xl' />
                                    <div className='w-full mt-2'>
                                        <h2 className='text-[#212121] font-semibold text-start text-sm lg:text-base'>{i?.name}</h2>
                                        <div className='flex items-center gap-1'>
                                            <SlLocationPin className='text-[#FFB703]' />
                                            <Link
                                                to="#"
                                                onClick={(e) => openInNewTab(generateMapsUrl(i?.address), e)}
                                                className='w-full text-start truncate lg:text-center text-xs leading-normal lg:text-base inline-block'
                                            >
                                                {i?.address}
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                        )}
                    </Swiper>

                </div>
            </div>
        </section>
    )
}

export default HotelsSection