import React, { useEffect, useState } from "react";
import "./modal.css";

export default function Modal({ isOpen, title, content, onClose, width }) {
  // Prevent page scrolling when the modal is open


  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Prevent scrolling when modal is open
    } else {
      document.body.style.overflow = "auto"; // Restore scrolling when modal is closed
    }
  }, [isOpen]);

  // If the modal is not open, return null
  if (!isOpen) return null;

  return (
    <div className={`modal ${isOpen ? "fade-in" : "fade-out"}`}>

      <div
        className={
          title === "Booking Process"
            ? " booking_modal_content modal-content"
            : " modal-content"
        }
      >
        <div className="title_close_btn_modal_container">

          <h2>{title}</h2>

        
          <button className="close-button" onClick={onClose}>
            <i className="fa fa-times" aria-hidden="true" />
          </button>
        </div>

        <div className="content_container_modal">{content}</div>
      </div>


    </div>
  );
}
