import React, { useEffect } from 'react'
import { Book_banner } from '../../helper/imageConstant'
import HeroSection from './HeroSection';
import HotelsSection from './HotelsSection';
import ShuttleOffersSection from './ShuttleOffersSection';
import ServicesSection from './ServicesSection';
import ClientSection from './ClientSection';
import OurpartnerSection from './OurpartnerSection';
import CtaSection from './CtaSection';
import FaqSection from './FaqSection';
import ContactSection from './ContactSection';
import PackageSection from './PackageSection';
import { fetchFaqData, fetchProducts, getCategory, getGeneralSetting, getHotelList, getOffersList, getPartnerlist } from '../../statemanagement/common/action';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../component/Loader';
import Modal from '../../component/Modal/Modal';
import LocationModal from '../../component/Modal/LocationModal/LocationModal';
import DateAndTimeAvailability from '../../component/Modal/DateAndTimeAvailability';
import AdultChildModal from '../../component/Modal/AdultChildModal/AdultChildModal';
import UserDetailsPopup from '../../component/UserDetailsPopup';
import Stepper from '../../component/Stepper/Stepper';
import { HANDLE_HOME_PAGE_MODAL, TRACK_STEPPER_STEPS } from '../../statemanagement/common/types';

const Home = () => {
  let dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFaqData())
    dispatch(fetchProducts())
    dispatch(getCategory())
    dispatch(getPartnerlist())
    dispatch(getGeneralSetting())
    dispatch(getHotelList())
    dispatch(getOffersList())
  }, [])

  const {faqdatalist,
    commonLoader, 
    categoryList, 
    partnerList, 
    generalSettingList, 
    hotelList, 
    OffersList, 
    isHomePageModalOpen,
    bookingProcessStepsTracker
   } = useSelector((state) => state.common)

   console.log('bookingProcessStepsTracker', bookingProcessStepsTracker)


  const closeModal = () => {
  
    dispatch({ type: HANDLE_HOME_PAGE_MODAL, data: false });
    dispatch({ type: TRACK_STEPPER_STEPS, data:0 });

  };


  return (
    <>
      {commonLoader && <Loader type={"bus"} />}
      <Modal
        isOpen={isHomePageModalOpen}
        width={"width"}
        title={"Booking Process"}
        onClose={closeModal}
        content={
          <Stepper
            steps={["Step 1", "Step 2", "Step 3","Step 4"]}
            stepContent={[
              <LocationModal  />,      // here locationModal is reuse component
              <DateAndTimeAvailability />,
              <AdultChildModal />,
              <UserDetailsPopup />
           
            ]}
          />
        }
      />
      <HeroSection booking />
      <HotelsSection data={hotelList} />
      <ShuttleOffersSection data={OffersList} />
      <ServicesSection data={categoryList} />
      <ClientSection />
      <PackageSection />
      <section className='p-0 my-5 lg:my-10'>
        <div className='relative w-full h-full'>
          <img src={Book_banner} alt='' className='w-full h-[332px] lg:h-full mx-auto object-cover' />
          <div className='absolute bg-[#000000] bg-cover bg-center bg-opacity-60 top-0 left-0 right-0 bottom-0 flex justify-center items-center h-full'>
            <div className='text-center'>
              <h2 className='mx-auto lg:w-full text-2xl lg:text-[32px] text-[#fff] font-semibold mb-5'>Ready to take on NYC's adventures?</h2>
              <p className='lg:w-[55%] mb-2 lg:mb-0 px-2  mx-auto font-normal text-sm lg:text-xl leading-normal text-[#fff] pb-4 lg:pb-3'>Golden Eagle offers daily door-to-door shuttles to NYC for hotel guests in North Bergen and Jersey City, NJ. Scheduled runs require tickets purchased one hour in advance.</p>
              <button className="text-[#212121] font-semibold rounded-full uppercase text-base leading-normal bg-gradient-to-r from-[#FFB703] to-[#FD9D01] px-8 py-3">book now</button>
            </div>
          </div>
        </div>
      </section>
      <OurpartnerSection data={partnerList} />
      <CtaSection data={generalSettingList} />
      <FaqSection
        data={faqdatalist}
      />
      <ContactSection />
    </>
  )
}

export default Home