import React, { useState } from 'react'
import { NOT_FOUND_IMG } from '../../helper/imageConstant'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';  // Import Swiper styles
import { Link } from 'react-router-dom';
import InquiryDetailsPopup from '../../component/InquiryDetailsPopup'

const ServicesSection = (props) => {
    const { data } = props

    const [isPopupOpen, setPopupOpen] = useState(false);

    const togglePopup = () => {
        setPopupOpen(!isPopupOpen);
    };

    function handleNavigation(i,j){
    
        togglePopup()
    }

    return (
        <section className='max-w-7xl mx-auto my-5 lg:my-10'>
            <div className='text-center relative'>
                <h2 className='text-2xl lg:text-[32px] text-[#212121] font-semibold mb-2'>Services</h2>
                <p className='lg:w-2/4 mx-auto lg:title-line lg:pb-3'>Whether you're catching a flight, exploring local attractions,
                    or organizing a group trip, we offer convenient, stress-free solutions tailored to your
                    needs.</p>
            </div>

            <div className='max-w-7xl mx-auto py-5 lg:py-10'>
          
                <div className=" w-full overflow-x-scroll flex mx-4">
                
                    {/* <Swiper
                        spaceBetween={20}
                        slidesPerView={'auto'}
                        loop={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                                loop: true,
                            },
                            768: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                                loop: true,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 20,
                                loop: true,
                            },
                        }}
                        className="mySwiper services_slider"
                    > */}
                     
                        
                        {data?.map((i, j) => (
                            // <SwiperSlide key={j} className="">
                                <div key={j} className="border border-[#FFB703] rounded-[60px] p-6 w-full min-h-[460px] h-full flex flex-col justify-between text-center">
                                    <div>
                                        <img
                                            src={i?.img || NOT_FOUND_IMG}
                                            alt={i?.img}
                                            className="w-full h-[150px] mx-auto object-cover mb-4 rounded-[50px]"
                                        />
                                    </div>
                                    <div className="flex-grow flex flex-col justify-between">
                                        {/* <div> */}
                                            <h2 className="w-[70%] lg:w-full mx-auto text-lg font-semibold mb-2">
                                                {i?.name}
                                            </h2>
                                            <p className="w-[80%] lg:w-full mx-auto text-[#212121] text-[15px] line-clamp-4">
                                                {i?.desc}
                                            </p>
                                        {/* </div> */}
                                        <div className="flex justify-center items-center" 
                                        // onClick={()=>handleNavigation(i,j)}
                                        >
                                            <Link   to={`/product/${i?.id}`}>
                                                <button className="bg-gradiant-yellow-secondary text-[#000000] font-light rounded-full p-4 group/item">
                                                    <svg
                                                        className="group-hover/item:scale-125"
                                                        width="18"
                                                        height="18"
                                                        viewBox="0 0 19 18"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M7.36442 1.18568C7.87986 1.71943 9.17682 2.00728 10.3566 2.16083C11.8764 2.36133 13.4238 2.32257 14.919 1.9894C16.0401 1.73952 17.2941 1.32789 17.9817 0.663796M17.9817 0.663796C17.2941 1.32789 16.8382 2.56746 16.5501 3.67841C16.1656 5.1618 16.0729 6.70686 16.2188 8.23134C16.3318 9.41648 16.5756 10.7241 17.0896 11.2564M17.9817 0.663796L0.717595 17.3356"
                                                            stroke="black"
                                                        />
                                                    </svg>
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            // </SwiperSlide>
                        ))}
                    {/* </Swiper> */}
                </div>

            </div>
            {/* <UserDetailsPopup isOpen={isPopupOpen} onClose={togglePopup} /> */}
            {/* <InquiryDetailsPopup isOpen={isPopupOpen} onClose={togglePopup} /> */}
                        

        </section>
    )
}

export default ServicesSection