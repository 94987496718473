import React, { useEffect, useState } from "react";
import "./datetimecard.css";

const DateTimeCard = (props) => {

  const [timerDetails, setTimerDetails] = useState({});
  useEffect(() => {
    setTimerDetails(props);
  }, [props]);
  return (
    <div className="flight-card">
      <div className="time-section">
        <p className="departure-time">{timerDetails?.startTime}</p>
        <p className="duration">till {timerDetails?.endTime}</p>
      </div>
      <div className="route-section">
        <span>{timerDetails?.route}</span>
      </div>
      <div className="single_trip_container">
        {timerDetails?.priceList &&
          timerDetails?.priceList?.map((i,index) => (
           
              <p key={index}>
                <span>{i?.name?.toUpperCase()}</span>&nbsp; <br />
                {i?.round_ride
                  ? `Round Trip: ${i?.round_ride}`
                  : `Single Ride: ${i?.single_ride}`}{" "}
                <br />
              </p>
         
          ))}
      </div>
      <button
        className={`details-button ${
          timerDetails?.selectTime === timerDetails?.id
            ? "bg-red-400 text-cyan-50"
            : "bg-white"
        }`}
        onClick={timerDetails?.onMoreDetails}
      >
        {/* More Details */}
        {timerDetails?.selectTime === timerDetails?.id ? "Selected"  :  "Select Time" }
      </button>
    </div>
  );
};

export default DateTimeCard;
