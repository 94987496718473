import React, { useEffect, useRef, useState } from "react";
import "./dateandtimeavailability.css";
import moment from "moment";
import DateTimeCard from "../DateTimeCard/DateTimeCard";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useDispatch, useSelector } from "react-redux";
import { handleStepperFormFieldPayload } from "../../statemanagement/common/action";
import { BOOKING_INFO_DETAILS } from "../../statemanagement/common/types";

export default function DateAndTimeAvailability(props) {
  //note for me
  // departureDate= 0 // returnDate = 1

  const dispatch = useDispatch();

  const [selectedDateTime, setSelectedDateTime] = useState(0);
  const [selectedDepartDate, setSelectedDepartDate] = useState(); // Default to today's date
  const [selectedReturnDate, setSelectedReturnDate] = useState(); // Default to today's date
  const { productById, stepperFormData, bookingInfoDetails } = useSelector(
    (state) => state?.common
  );

  const [oneWayList, setOneWayList] = useState({});
  const [sessionDepartTimerList, setSessionDepartTimerList] = useState([]);
  const [sessionReturnTimerList, setSessionReturnTimerList] = useState([]);
  const [roundTripList, setRoundTripList] = useState({});

  const [storeDateTime, setStoreDateTime] = useState({});
  const departDatesRef = useRef(null);
  const returnDatesRef = useRef(null);

  const scrollDates = (offset, type) => {
    const ref = type === "departureDate" ? departDatesRef : returnDatesRef;
    if (ref.current) {
      ref.current.scrollLeft += offset;
    }
  };

  useEffect(() => {
    // Check for one-way trip data
    if (productById?.oneway?.id) {
      const oneWaySession = productById.oneway.session;
      const timerDisplay = moment(oneWaySession?.from_date).format(
        "YYYY-MM-DD"
      );

      setOneWayList(productById.oneway);
      setSelectedDepartDate(
        moment(oneWaySession?.from_date).format("D MMM YYYY")
      );

      // Filter session timings
      const departTime =
        oneWaySession?.session_timing?.filter(
          (i) => i.start_date === timerDisplay
        ) || [];
      setSessionDepartTimerList(departTime);
    }

    // Check for round-trip data
    if (productById?.roundtrip?.id) {
      const roundTripSession = productById.roundtrip.session;
      const timerDisplay = moment(roundTripSession?.from_date).format(
        "YYYY-MM-DD"
      );

      setRoundTripList(productById.roundtrip);
      setSelectedReturnDate(
        moment(roundTripSession?.from_date).format("D MMM YYYY")
      );

      // Filter session timings
      const returnTime =
        roundTripSession?.session_timing?.filter(
          (i) => i.start_date === timerDisplay
        ) || [];
      setSessionReturnTimerList(returnTime);
    }
  }, [productById]);

  let productData = productById?.oneway?.session;
  // const fromDate = productData?.from_date;

  const untilDate = productData?.until_date;

  const generateDates = (startDate, untilDate) => {
    const today = moment().startOf("day");
    const start = moment(startDate).isAfter(today) ? moment(startDate) : today;

    const numDays = moment(untilDate).diff(start, "days") + 1;

    if (numDays < 1) {
      // If untilDate is before today, return an empty array
      return [];
    }
    return Array.from({ length: numDays }, (_, index) =>
      start.clone().add(index, "days").format("D MMM YYYY")
    );
  };
  // Generate dates dynamically (starting from today until a specific date)
  const fromDate = moment().format("YYYY-MM-DD"); // Use today's date as the start
  const dates = generateDates(fromDate, untilDate);

  const handleDateSelection = (date, name) => {
    if (name === "departureDate") {
      const timerDisplay = moment(date).format("YYYY-MM-DD");
      const displayTime = oneWayList?.session?.session_timing.filter(
        (i) => i.start_date === timerDisplay
      );
      setSelectedDepartDate(date);
      setSessionDepartTimerList(displayTime);
    } else if (name === "returnDate") {
      const timerDisplay = moment(date).format("YYYY-MM-DD");
      const displayTime = roundTripList?.session?.session_timing.filter(
        (i) => i.start_date === timerDisplay
      );

      setSelectedReturnDate(date);
      setSessionReturnTimerList(displayTime);
    }
  };

  const onMoreDetails = (i, name) => {
    if (name === "departureDate") {
      dispatch(
        handleStepperFormFieldPayload(name, i.id, i.start_date, "step2")
      );
      dispatch(
        handleStepperFormFieldPayload(
          "departureTime",
          i.id,
          i.start_time,
          "step2"
        )
      );
      dispatch({
        type: BOOKING_INFO_DETAILS,
        data: {
          totalAvailability: i?.availability,
        },
      });
      setStoreDateTime((prev) => ({
        ...prev,
        departureDate: { ...i },
      }));
    } else {
      dispatch(
        handleStepperFormFieldPayload(name, i.id, i.start_date, "step2")
      );
      dispatch(
        handleStepperFormFieldPayload("returnTime", i.id, i.start_time, "step2")
      );
      setStoreDateTime((prev) => ({
        ...prev,
        returnDate: { ...i },
      }));
    }
  };

  function handleDateAndTime(e) {
    setSelectedDateTime(e);
  }

  return (
    <div>
      <div className="modal-container">
        <div className="trip-selection">
          <button
            className={`trip-button ${selectedDateTime === 0 ? "active" : ""}`}
            onClick={() => handleDateAndTime(0)}
          >
            {/* One Way */}
            Departure date and time
          </button>
          {productById?.is_roundtrip &&
          stepperFormData?.step1?.formField?.tripType?.value === "roundtrip" ? (
            <>
              <button
                className={`trip-button ${
                  selectedDateTime === 1 ? "active" : ""
                }`}
                disabled={productById?.is_roundtrip ? false : true}
                onClick={() => handleDateAndTime(1)}
              >
                {/* Round Trip */}
                Return date and time
              </button>
            </>
          ) : null}
        </div>

        <>
          {!selectedDateTime ? (
            <div className="date-selection1">
              {/* <h3 className="select_trip_text">Select departureDate date and time</h3> */}
              <div className="date-options-wrapper">
                <button
                  className="scroll-btn left"
                  onClick={() => scrollDates(-100, "departureDate")}
                >
                  <i className="fa fa-arrow-left" aria-hidden="true" />{" "}
                  {/* Left arrow */}
                </button>
                <div className="date-options" ref={departDatesRef}>
                  {dates.map((date, index) => (
                    <button
                      key={index}
                      className={`date-button ${
                        selectedDepartDate === date ? "selected" : ""
                      }`}
                      onClick={() => handleDateSelection(date, "departureDate")}
                    >
                      {date}
                    </button>
                  ))}
                </div>
                <button
                  className="scroll-btn right"
                  onClick={() => scrollDates(100, "departureDate")}
                >
                  <i className="fa fa-arrow-right" aria-hidden="true" />
                </button>
              </div>
            </div>
          ) : (
            <div className="date-selection1">
              <div className="date-options-wrapper">
                <button
                  className="scroll-btn left"
                  onClick={() => scrollDates(-100, "returnDate")}
                >
                  &#8592; {/* Left arrow */}
                </button>
                <div className="date-options" ref={returnDatesRef}>
                  {dates.map((date, index) => (
                    <button
                      key={index}
                      className={`date-button ${
                        selectedReturnDate === date ? "selected" : ""
                      }`}
                      onClick={() => handleDateSelection(date, "returnDate")}
                    >
                      {date}
                    </button>
                  ))}
                </div>
                <button
                  className="scroll-btn right"
                  onClick={() => scrollDates(100, "returnDate")}
                >
                  &#8594; {/* Right arrow */}
                </button>
              </div>
            </div>
          )}

          <div className="date_time_card_container">
            {!selectedDateTime
              ? sessionDepartTimerList &&
                sessionDepartTimerList?.map((item, index) => {
                  return (
                    <DateTimeCard
                      key={index}
                      startTime={item?.start_time}
                      endTime={item?.end_time}
                      selectTime={storeDateTime?.departureDate?.id}
                      id={item?.id}
                      route={oneWayList?.name}
                      priceList={oneWayList?.product_price}
                      onMoreDetails={() => onMoreDetails(item, "departureDate")}
                    />
                  );
                })
              : sessionReturnTimerList &&
                sessionReturnTimerList?.map((item, index) => {
                  return (
                    <DateTimeCard
                      key={index}
                      startTime={item?.start_time}
                      endTime={item?.end_time}
                      selectTime={storeDateTime?.returnDate?.id}
                      id={item?.id}
                      route={roundTripList?.name}
                      priceList={roundTripList?.product_price}
                      onMoreDetails={() => onMoreDetails(item, "returnDate")}
                    />
                  );
                })}
          </div>

          {sessionDepartTimerList?.length < 1 && (
            <>
              <div className="no_data_bus_route">
                <img src={"/images/stockimage/nodatabus.svg"} loading="lazy" />
              </div>
            </>
          )}
        </>

        {!sessionDepartTimerList?.length < 1 && (
          <div className="flex  left_round_trip_outside_container">
            <div className="left_round_trip_container border  border-red-400  m-1 p-2 rounded-md">
              <div className="date-selection">
                <h3 className="selected_date_time_round_trip">
                  Selected Departure:{" "}
                  <span>
                    {storeDateTime?.departureDate?.start_date
                      ? storeDateTime?.departureDate?.start_date
                      : "MM/DD/YYYY"}
                  </span>{" "}
                  |{" "}
                  <span>
                    {storeDateTime?.departureDate?.start_time
                      ? storeDateTime?.departureDate?.start_time +
                        " " +
                        "to" +
                        " " +
                        storeDateTime?.departureDate?.end_time
                      : "HH:MM AM/PM"}
                  </span>
                </h3>
              </div>
            </div>
            {productById?.is_roundtrip &&
            stepperFormData?.step1?.formField?.tripType?.value ===
              "roundtrip" ? (
              <div className="left_round_trip_container border border-red-400 m-1 p-2 rounded-md">
                <div className="date-selection">
                  <h3 className="selected_date_time_round_trip">
                    Selected Return :
                    <span>
                      {storeDateTime?.returnDate?.start_date
                        ? storeDateTime?.returnDate?.start_date
                        : "MM/DD/YYYY"}
                    </span>{" "}
                    |{" "}
                    <span>
                      {storeDateTime?.returnDate?.start_time
                        ? storeDateTime?.returnDate?.start_time +
                          " " +
                          "to" +
                          " " +
                          storeDateTime?.returnDate?.end_time
                        : "HH:MM AM/PM"}
                    </span>
                  </h3>
                </div>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
