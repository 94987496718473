import React, { useState } from "react";
import "./bookingmodule.css";
import DateAndTimeAvailability from "../Modal/DateAndTimeAvailability";
import Modal from "../Modal/Modal";
import AdultChildModal from "../Modal/AdultChildModal/AdultChildModal";
import { useNavigate } from "react-router-dom";
import {
  getFromLocation,
  getPriceDetails, 
  getToLocation,
} from "../../statemanagement/common/action";
import { useDispatch, useSelector } from "react-redux";
import {
  HANDLE_HOME_PAGE_MODAL,
  STORE_STEPPER_FORMDATA,
  USER_SELECTED_ROUTE
} from "../../statemanagement/common/types";

export default function BookingModule() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedService, setSelectedService] = useState("");
  const {
    toLocationList,
    productList,
    fromLocationList,
    stepperFormData
   
  } = useSelector((state) => state.common);
  const [productDetails, setProductDetails] = useState({
    product: {id:"", name:""},
    fromLocation: {id:"", name:""},
    toLocation: {id:"", name:""},
  });
  const [storeErrMsg,setStoreErrMsg] = useState({
    product: "",
    fromLocation: "",
    toLocation: "",
  })

  const handleSearch = () => {
    const errors = validateProductDetails(productDetails);

    if (Object.keys(errors).length > 0) {
      setStoreErrMsg(errors); // Set specific field errors
      return;
    }

    setStoreErrMsg({}); // Clear errors if validation passes

    // add data to store userSelected route
    dispatch(
      {type:USER_SELECTED_ROUTE, data:productDetails}
    );
    //open the home page modal for Booking process
    dispatch({type:HANDLE_HOME_PAGE_MODAL, data:true})
  };


  const validateProductDetails = (details) => {
    const errors = {};
    if (!details.product) {
      errors.product = "Please select a service.";
    }
    if (!details.fromLocation?.id) {
      errors.fromLocation = "Please select a 'From' location.";
    }
    if (!details.toLocation?.id) {
      errors.toLocation = "Please select a 'To' location.";
    }
    return errors;
  };

 

  
  // finding the location of service as per the product
  function handleProductById(e) {
    let { name, selectedIndex } = e.target;
    let selectedProduct = productList.at(selectedIndex - 1)
    dispatch(getFromLocation(selectedProduct?.id));
    setProductDetails((prev) => ({
      ...prev,
      [name]: {id:selectedProduct?.id, name: selectedProduct?.name },
    }));
    setStoreErrMsg((prev) => ({ ...prev, [name]: "" })); // Clear error on change
  }

  // storing from location and to location 
  //and searching for the "to location" as per the selected from location

  const handleLocation = (e) => {
    const { name, value, selectedIndex } = e.target;
        if (name === "fromLocation") {
      let selectedFromLocation = fromLocationList.at(selectedIndex-1)
      setProductDetails((prev) => ({
        ...prev,
        [name]: {id:selectedFromLocation.id, name:selectedFromLocation.name},
        toLocation: {id:"",name:" "},
      }));
      dispatch(getToLocation(productDetails?.product?.id, selectedFromLocation.id));
    } else {
      let selectedToLocation = toLocationList.at(selectedIndex-1)
          // fetch price details about the route as per the session
          dispatch(
        getPriceDetails(productDetails?.fromLocation?.id, selectedToLocation.id, productDetails?.product?.id)
      );
      // store product details in to location 
      setProductDetails((prev) => ({
        ...prev,
        [name]: {id:selectedToLocation.id, name:selectedToLocation.name},
      }));
    }
    
    setStoreErrMsg((prev) => ({ ...prev, [name]: "" })); // Clear error on change
  };


  return (
    <div className="booking_module_container">
       <label>Services</label>
      <select
        className="dropdown_booking_module"
        name="product"
        value={productDetails?.product?.name}
        onChange={handleProductById}
      >
        <option>
          {productDetails?.product?.id
            ? productList.find((i) => i.id == productDetails?.product?.id)?.name
            : "Select service"}
        </option>
        {productList.map((i) => (
          <option key={i.id} value={i.id}>
            {i?.name}
          </option>
        ))}
      </select>
      <label className="error_msg_txt">{storeErrMsg.product}</label>
      <div className="two_booking_drop_down_container">
        <div className="lable_input_group_container">
          <label>From</label>
          <div className="input_group_booking_module">
            <span className="icon_booking_module"><i className="fa  fa-map-marker" aria-hidden="true" /></span>
            <select
              className="dropdown_booking_module"
              value={productDetails?.fromLocation?.name}
              name="fromLocation"
              onChange={handleLocation}
            >
              <option>
                {productDetails?.fromLocation?.id
                  ? fromLocationList.find(
                      (i) => i.id === productDetails?.fromLocation.id
                    )?.name
                  : " Choose From Location"}
              </option>
              {fromLocationList &&
                fromLocationList?.map((i) => (
                  <option key={i.id} value={i.id}>
                    {i.name}
                  </option>
                ))}
            </select>
          </div>
          <label className="error_msg_txt">{storeErrMsg.fromLocation}</label>
        </div>

        <div className="lable_input_group_container">
          <label>To</label>
          <div className="input_group_booking_module">
            <span className="icon_booking_module"><i className="fa  fa-map-marker" aria-hidden="true" /></span>
            <select
              className="dropdown_booking_module"
              value={productDetails?.toLocation?.name}
              name="toLocation"
              onChange={handleLocation}
            >
              <option>
                {productDetails?.toLocation?.id
                  ? toLocationList.find(
                      (i) => i.id === productDetails?.toLocation?.id
                    )?.name
                  : " Choose From Location"}
              </option>
              {toLocationList &&
                toLocationList?.map((i) => (
                  <option key={i.id} value={i.id}>
                    {i.name}
                  </option>
                ))}
            </select>
          </div>
          <label className="error_msg_txt">{storeErrMsg.toLocation}</label>
        </div>
        <button className="search_button_booking_module" onClick={handleSearch}>
          🔍
        </button>
      </div>

    </div>
  );
}
