export const FETCH_Product = '/api/product';
export const FETCH_FAQ = '/api/faqs';
export const FETCH_CATEGORY = '/api/category';
export const FETCH_PARTNERS = '/api/partners';
export const SAVE_CONTACTUS = '/api/contact-us';
export const GENERAL_SETTING = '/api/general-setting';
export const FETCH_HOTEL = '/api/hotels';
export const FETCH_OFFERS = '/api/offers';
export const SAVE_USERS = '/api/booking';
export const PRICE_DETAILS = '/api/get-price';


//Location 

export const FROM_LOCATION = "/api/from-locations?product_id="
export const TO_LOCATION = "/api/to-locations?product_id="