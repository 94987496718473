export const COMMON_LOADER = "COMMON_LOADER";
export const FAQ_LIST = 'FETCH_FAQ';
export const PRODUCT_LIST = 'FETCH_PRODUCT_DATA';
export const PRODUCT_BYID = 'FETCH_PRODUCT_BYID_DATA';
export const CATEGORY_BYID = 'FETCH_CATEGORY_BYID_DATA';
export const CATEGORY_LIST = 'CATEGORY_LIST';
export const PARTNERS_LIST = 'PARTNERS_LIST';
export const TO_LOCATIONS = 'TO_LOCATIONS';
export const FROM_LOCATIONS = 'FROM_LOCATIONS';
export const SAVE_CONTACTDATA = 'SAVE_CONTACTDATA';
export const GENERAL_SETTING_LIST = 'GENERAL_SETTING_LIST';
export const HOTEL_LIST = 'HOTEL_LIST';
export const OFFERS_LIST = 'OFFERS_LIST';
export const SAVE_USER = 'SAVE_USER';
export const HANDLE_USER_DATA = 'HANDLE_USER_DATA';


//Handle Booking process
export const USER_SELECTED_ROUTE = 'USER_SELECTED_ROUTE';
export const BOOKING_INFO_DETAILS = 'BOOKING_INFO_DETAILS';




//handle Modal operations
export const HANDLE_HOME_PAGE_MODAL = 'HANDLE_HOME_PAGE_MODAL';
export const HANDLE_PRODUCT_PAGE_MODAL = 'HANDLE_PRODUCT_PAGE_MODAL';
export const HANDLE_SCHEDULE_PAGE_MODAL = 'HANDLE_SCHEDULE_PAGE_MODAL';


// Track Booking process stepper and perform validation
export const TRACK_STEPPER_STEPS = 'TRACK_STEPPER_STEPS';
export const STORE_STEPPER_FORMDATA = 'STORE_STEPPER_FORMDATA';
export const STEPPER_FORMDATA_ERROR_HANDLING = 'STEPPER_FORMDATA_ERROR_HANDLING';



//POPUP 
export const SELECT_TIMER_POPUP = "SELECT_TIMER_POPUP"
export const PASSENGER_DETAILS_POPUP = "PASSENGER_DETAILS_POPUP"
export const BOOKING_DATA = "BOOKING_DATA"
export const USER_DETAILS_POPUP = "USER_DETAILS_POPUP"


export const ADULT_CHILD_POPUP = "ADULT_CHILD_POPUP"
export const LOCATION_POPUP = "LOCATION_POPUP"
